/* PRODUCT LIST WITH IMAGE, ADVANCED
----------------------------------------------------------------------------- */
.product-advanced-wrapper {
  clear: both;
  margin: 0 10px;
}

.product-advanced-row {
  border-bottom: 1px solid #dfdfdf;
}

.product-advanced-image {
  float: left;
  padding: 10px;
  width: 100px;
}

.product-advanced-image img {
  vertical-align: bottom;
}

.product-advanced-column2 {
  float: left;
  padding: 10px;
  width: 200px;
}

.product-advanced-column3 {
  float: right;
}

.product-advanced-description {
  float: left;
  width: 100%;
}

.product-advanced-name a {
  font-weight: bold;
  font-size: 1em;
}

.product-advanced-subname {
  font-style: italic;
  padding-top: 2px;
}

.product-advanced-article {
  padding-top: 2px;
}


.product-advanced-price-box,
.product-advanced-price-stock-status,
.product-advanced-quantity
{
  display: table-cell;
  vertical-align: top;
  padding: 10px;
}

.product-advanced-price-buttons,
.product-advanced-info-buttons
{
  display: table-cell;
  vertical-align: top;
}

.product-advanced-price-box {
  text-align: right;
}

.product-advanced-price-stock-status {
  text-align: center;
  display: none;
}

span.stock-status-label {
  display: inline-block;
  font-weight: bold;
}

.product-advanced-quantity {
  display: none;
}

input.advanced-product-quantity-value {
  width: 20px;
  height: 15px;
  text-align: center;
  float: right;
  margin-right: 5px;
}
