//
//  General style for buttons
//

.button {
  cursor: pointer;
  text-transform: $button-text-transform;
  letter-spacing: $button-letter-spacing;
}

a.view-button-related-products {
  display: none;
  border: 0px !important;
}

a.buy-button-first-text {
  margin: 0;
  font-size: 13px;
}

.get-customer-info-btn, .fancybox-iframe-login {
  background: #efefef;
  font-size: .8em;
  padding: 4px 10px;
  margin: 11px 0;
}

a.checkout-text {
  font-size: $js-button-checkout-font-size !important;
  padding: $js-button-checkout-padding !important;
  color: $js-button-checkout-font-color !important;
  background-color: $js-button-checkout-background-color !important;
  border: none !important;
  display: block !important;
  height: $js-small-cart-height !important;
  line-height: $js-small-cart-height !important;
  position: relative !important;
  text-transform: none !important;
  letter-spacing: 0 !important;

  &:hover {
    //padding: $js-button-checkout-padding;
    background-color: $js-button-checkout-background-color-hover !important;
    color: $js-button-checkout-font-color-hover !important;
    border: none !important;
  }
  
  &::after {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    content: "\f105";
    font-size: $js-button-checkout-icon-size;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: ($js-button-checkout-icon-size / -2);
    right: 10px;
    line-height: 1;
  }

  &.button-disabled {
    font-size: $js-button-checkout-font-size !important;
    padding: $js-button-checkout-padding !important;
    color: #888888 !important;
    background-color: $js-button-checkout-background-color !important;
    border: none;

    span,
    &::after {
      opacity: 0.5;
    }
  }
}



// $padding - Used to build padding for buttons Default: $button-med ||= rem-calc(12)
// $bg - Primary color set in settings file. Default: $button-bg.
// $radius - If true, set to button radius which is $global-radius || explicitly set radius amount in px (ex. $radius:10px). Default:false.
// $full-width - We can set $full-width:true to remove side padding extend width. Default:false.
// $disabled - We can set $disabled:true to create a disabled transparent button. Default:false.
// $is-prefix - Not used? Default:false.
// $bg-hover - Button Hover Color - Default null - see button-style mixin
// $border-color - Button Border Color - Default null - see button-style mixin
// $transition - We can control whether or not to include the background-color transition property - Default:true.
a.buy-button-first-text,
a.advanced-buy-button-text,
a.buy-button-search,
.product-wrapper a.buy-button,
.purchase-block-buy a.buy-button,
a.buy
{
  @include button(
  $padding: $js-button-buy-padding,
  $bg: $js-button-buy-background-color,
  $radius: $js-button-buy-radius,
  $full-width: true,
  $disabled: false,
  $is-prefix: false,
  $bg-hover: $js-button-buy-background-color-hover,
  $border-color: null,
  $transition: true
  );
  text-transform: $button-text-transform;
  letter-spacing: $button-letter-spacing;
  font-size: $js-button-buy-font-size;
}

.purchase-block-buy a.buy-button
{
  @include button(
  $padding: $js-button-purchase-padding,
  $bg: $js-button-purchase-background-color,
  $radius: $js-button-purchase-radius,
  $full-width: true,
  $disabled: false,
  $is-prefix: false,
  $bg-hover: $js-button-purchase-background-color-hover,
  $border-color: null,
  $transition: true
  );
  text-transform: $button-text-transform;
  letter-spacing: $button-letter-spacing;
  font-size: $js-button-purchase-font-size;
}


.FrontRestProducts a.button-info,
a.advanced-info-button-text,
.product-wrapper a.button-info,
.releware-item-wrapper a.button-info,
a.view-button,
a.view-button-related-products
{
  @include button(
  $padding: $js-button-info-padding,
  $bg: $js-button-info-background-color,
  $radius: $js-button-info-radius,
  $full-width: true,
  $disabled: false,
  $is-prefix: false,
  $bg-hover: $js-button-info-background-color-hover,
  $border-color: null,
  $transition: true
  );
  text-transform: $button-text-transform;
  letter-spacing: $button-letter-spacing;
  font-size: $js-button-info-font-size;
}

// News button
.news-list-item-link .button,
.NewsListArchiveItemLink .button
{
  @include button(
  $padding: $js-button-news-padding,
  $bg: $js-button-news-background-color,
  $radius: $js-button-news-radius,
  $full-width: false,
  $disabled: false,
  $is-prefix: false,
  $bg-hover: $js-button-news-background-color-hover,
  $border-color: null,
  $transition: true
  );
  text-transform: $button-text-transform;
  letter-spacing: $button-letter-spacing;
}

.NewsListArchiveItemLink .button,
.NewsListArchiveItemLink .button:hover {
  margin-top: 4px;
  display: inline-block;
}

a.button-disabled {
  color: $js-button-default-disabled-color;
  cursor: default;
  text-transform: $button-text-transform;
  letter-spacing: $button-letter-spacing;
}


.search-box-button a, .search-box-button img,
a.button-info, a.button-info img,
a.buy-button, a.buy-button img,
.offers-list-item-more-info a, .offers-list-item-more-info img,
.newsletter-box-button input,
.NewsArchiveButton a, .NewsArchiveButton img,
a.ButtonInfo, a.ButtonInfo img,
a.ButtonBuy, a.ButtonBuy img,
a.RelatedProductMoreInfoButton, a.RelatedProductMoreInfoButton img,
a.RelatedProductBuyButton, a.RelatedProductBuyButton img,
table.ProductListHorizontalAdvanced table.ButtonBuyContainer td *,
table.ProductListHorizontalAdvanced .ButtonInfo * {
  display: block;
  cursor: pointer;
  text-transform: $button-text-transform;
  letter-spacing: $button-letter-spacing;
}

.CheckoutButtonEnabled a:hover img, .CheckoutButtonEnabled a:active img,
.MyPagesButtonEnabled a:hover img, .MyPagesButtonEnabled a:active img,
.LogInOutButton a:hover img, .LogInOutButton a:active img,
a.button-info:hover img, a.button-info:active img,
a.buy-button:hover img, a.buy-button:active img,
.offers-list-item-more-info a:hover img, .offers-list-item-more-info a:active img,
.newsletter-box-button input:hover, .newsletter-box-button input:active,
.NewsArchiveButton a:hover img, .NewsArchiveButton a:active img,
a.ButtonInfo:hover img, a.ButtonInfo:active img,
a.ButtonBuy:hover img, a.ButtonBuy:active img,
a.RelatedProductMoreInfoButton:hover img, a.RelatedProductMoreInfoButton:active img,
a.RelatedProductBuyButton:hover img, a.RelatedProductBuyButton:active img,
table.ProductListHorizontalAdvanced .ButtonContainer a:hover img, table.ProductListHorizontalAdvanced .ButtonContainer a:active img {
  opacity: 0;
  filter: alpha(opacity=0);
}


.CheckoutButton a:active,
.MyPagesButton a:active,
.LogInOutButton a:active,
.search-box-button a:active,
a.button-info:active,
a.buy-button:active,
.purchase-block-buy a.buy-button:active,
.offers-list-item-more-info a:active,
.newsletter-box-button:active,
.NewsArchiveButton a:active,
a.ButtonInfo:active,
a.ButtonBuy:active,
a.RelatedProductMoreInfoButton:active,
a.RelatedProductBuyButton:active,
table.ProductListHorizontalAdvanced .ButtonContainer a:active {
  background-position: left bottom;
}

.newsletter-box-button > .button {
  margin: 0px;
}

.button:hover {
  text-decoration: none;
}

.purchase-block-buy a.buy-button,
a.advanced-buy-button-text,
a.advanced-info-button-text
{
  width: auto;
  padding: 8px 12px;
}

a.advanced-buy-button-text,
a.advanced-info-button-text {
  min-width: 100px;
}
