
/* CONTENT PAGE
----------------------------------------------------------------------------- */

.PageDetailsWrapper {
  clear: both;
  overflow: hidden;
  margin: 0 auto;
  max-width: 70em;
  background-color: white;
  padding: 30px;
  border: 15px solid $uc-color;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.35);
  .PageDetailsImage {
    float: right;
    max-width: 50%;
    margin: 0 0 0 20px;

    img {
      margin: 0 0 20px;
    }
  }
}



//
//  Small
//
@media #{$small-only} {
  .PageDetailsWrapper {
    .PageDetailsImage {
      float: none;
      max-width: none;
      margin: 0;

      img {
        margin: 0 0 15px;
      }
    }
  }
}