
//
//  My pages
//
html.page-mypages {

  td.LoadCustomerProfileSelector span.Checkout {
    margin-right: 8px;
    display: inline-block;
  }

  td.CreateCustomerProfileSelector span.Checkout {
    margin-right: 8px;
    display: inline-block;
  }

  td.CustomerProfileLabel span.Checkout {
    margin-right: 8px;
    display: inline-block;
  }

  .Checkout.CheckoutInputRadio {
    margin-right: 8px;
    display: inline-block;
  }

  .Checkout.CheckoutInputTextSmall + span {
    margin-left: 5px;
    display: inline-block;
    color: $alert-color;
    font-weight: 700;
  }

  .Checkout.CheckoutInputTextBig label{
    margin-left: 6px;
  }

  .Checkout.CheckoutInputTextBig + span {
    margin-left: 5px;
    display: inline-block;
    color: $alert-color;
    font-weight: 700;
  }

}

#ctl00_ctl00_main_rightmain_myPagesControl_myPagesTabContainer_tabPanelCustomerAddresses_myCustomerInfo_cii_sem {
  background-color: #eeeeee;
}

.MyPagesSettingsMessageToShopSubjectCssClass input {
  max-width: 200px;
}

.MyPagesSettingsMessageToShopContentCssClass textarea {
  max-width: 200px;
}

.MyPagesSettingsNewsLetterCssClass input {
  margin-right: 8px;
}
