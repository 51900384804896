//
//  SETTINGS DYNAMIC CART
//

$js-dc-modal-width-large-up: 700px;
$js-dc-modal-width-medium: 580px;
$js-dc-modal-wrapper-padding: 20px;
$js-dc-modal-wrapper-border-width: 1px;
$js-dc-modal-wrapper-border-color: #fff;

$js-dc-modal-field-width-image: 50px;
$js-dc-modal-field-width-price: 100px;
$js-dc-modal-field-width-qty: 50px;
$js-dc-modal-field-width-delete: 20px;
$js-dc-modal-field-width-total: 100px;

$js-dc-dropdown-width-large-up: 600px;
$js-dc-dropdown-width-medium: 560px;
$js-dc-dropdown-wrapper-padding: 10px;
$js-dc-dropdown-field-width-image: 35px;
$js-dc-dropdown-field-width-price: 100px;
$js-dc-dropdown-field-width-qty: 50px;
$js-dc-dropdown-field-width-delete: 20px;
$js-dc-dropdown-field-width-total: 100px;
