/* SEARCH RESULT
----------------------------------------------------------------------------- */
.search-wrapper {
  background-color: white;
  padding: 10px;
}

.search-string-result {
  margin: 0 0 20px 0;
}

span.search-string {
  font-style: italic;
  text-decoration: underline;
}

.search-wrapper tr {
  border-bottom: 1px solid #dfdfdf;
}

.search-wrapper tr:first-child, .search-wrapper tr:last-child {
  border-bottom: 0;
}

.search-wrapper td {
  vertical-align: middle;
  padding: 10px;
}

table.search-result-table {
  margin: 0 0 20px 0;
  border-spacing: 10px;
}

table.search-result-table td {
  padding: 5px 5px 5px 0;
}

table.search-result-table td.search-order {
  text-align: center;
}

.search-image {
  position: relative;
}

table.search-result-table td.search-thumb {
  max-width: 200px;
}

table.search-result-table .search-image,
table.search-result-table .search-image img {
  max-width: 190px;
}

td.search-productnamne {
  //width: 70px;
  font-weight: bold;
}

td.SearchProductPrice {
  white-space: nowrap;
}

td.search-amount input {
  width: 30px;
  //height: 15px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

td.search-order a {
  float: right;
}

a.search-prev, a.search-next {
  font-weight: bold;
}

.search-previous-searches {
  .search-previous-searches-label {
    font-weight: 700;
  }
  .search-previous-string {
    margin-right: 8px;
    display: inline-block;
  }
}


//
//  Small & Medium Only
//
@media #{$medium-down} {

  table.search-result-table td.search-thumb {
    max-width: 50px;
  }

  table.search-result-table .search-image,
  table.search-result-table .search-image img {
    max-width: 40px;
  }
}

//
//  Medium only
//
@media #{$medium-only} {

  table.search-result-table td.search-thumb {
    max-width: 100px;
  }

  table.search-result-table .search-image,
  table.search-result-table .search-image img
  {
    max-width: 90px;
  }

}
