//
//  Fancybox Overrides
//

//TODO check this, login-button fancybox wont work
.fancybox-overlay.fancybox-overlay-fixed {
  z-index: 8000 !important;
}

//.fancybox-wrap {
//  width: 90% !important;
//}

//.fancybox-inner {
//  overflow: visible !important;
//  width: 100% !important;
//}

.upFastLogin {
  height: auto !important;
}

div.fast-login-box-inner {
  width: 270px !important;
}

div.fast-login-box-inner input[type=text] {
  width: 140px !important;
}

div.fast-login-box-inner input[type=password] {
  width: 140px !important;
}

.CustomerInfoInputCheckoutInputTextCssClass {
  margin-bottom: 12px;
}

#fastLogin_lblPassword {
  display: inline-block;
  margin-top: 8px;
}

#fastLogin_divForgotPassword,
#fastLogin_divLogIn {
  input {
    color: #333;
  }
}

#fastLogin_tbForgotPasswordEmailInput {
  margin-bottom: 12px;
}

//
//  Fancy-boxed "Terms & Conditions" in Checkout
//
.fancybox-inner #terms-and-conditions {
  max-width: 800px !important;
  width: 800px !important;
}

.device-width-small .fancybox-inner #terms-and-conditions,
.device-width-medium .fancybox-inner #terms-and-conditions {
  max-width: 100% !important;
  width: 100% !important;
}