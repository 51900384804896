//
//  Start Page
//

#languageOverlayWrapper {
  display: none !important;
}

.page-default {
  #path-nav {
    display: none;
  }
}

.page-default .startpage-image {
  text-align: center;
  margin-bottom: 25px;

  img {
    width: 100%;
    max-width: 1024px;
  }
}

.page-default .startpage-text {
  margin-bottom: 4px;
}

#startpage_list {
  ul {
    li {
      border: none;
      padding: 15px 15px 5px;
      .custom-item-text{
        h2{
          background: black;
          display: inline-block;
          color: white;
          padding: 5px 10px;
          margin-top: 10px;
          font-family: $font-family-sans-serif-2;
          letter-spacing: 1px;
        }
      }
      &:nth-of-type(1){
        background: $green;
      }
      &:nth-of-type(2){
        background: $purple;
      }
      &:nth-of-type(3){
        background: $yellow;
      }
      &:nth-of-type(4){
        background: $blue;
      }                  
      .product-info {
        text-transform: uppercase;
        @include product-name-h3
      }
      .product-wrapper {
        .product-image {
          padding-top: $js-startpage-wrapper-image-wrapper-height;
        }
        .product-buttons,
        .product-buttons:hover {
          margin: 0 0 5px 0;
          overflow: hidden;
          a {
            width: 100%;
          }
        }
      }
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 2)
  }
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 4)
  }
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
  #startpage_list .items-2 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 1.4);
  }
}

