html.page-responsive-checkout,
html.page-responsive-orderconfirmed {

  #ctl00_main_responsivecheckout_UpCheckoutHeader{
    position:relative;
    text-align: center;
    h1{
      background: $black;
      color: white;
      display: inline-block;
      padding: 5px 15px;
      font-family: $font-family-sans-serif-2;
      font-size: 30px;
      .fa-lock{
        display: none;
      }
      a{
        position: absolute;
        right: 0;
        top: -20px;
        font-family: $font-family-sans-serif-regular;
        &.change-customertype-button{
          right: 0;
        }
        &.responsive-login.cart-header-login-button{
          right: 0;
          top: 0;
        }
      }
    }
  }

  // Numbered bullets
  h2.component-header {
    background: $black;
    color: white;
    display: inline-block;
    padding: 5px 15px;
    font-family: $font-family-sans-serif-2;
    font-size: 30px;
    span.component-header-number {
      width: auto;
      height: auto;
      display: inline-block;
      padding: 0;
      margin-top: 0;
      background-color: transparent;
      margin-right: 6px;
      vertical-align: inherit;
      color: white;
      text-align: center;
      font-size: 30px !important;
      line-height: normal !important;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      border-radius: 100px;
      &:after{
        content: " - ";
      }
    }
  }
  .form-row{
    margin-bottom: 20px;
  }
  .form-label, .getcustomerinfo-header{
    background: $black;
    color: white;
    display: inline-block;
    text-transform: uppercase;
    padding: 1px 10px;
    font-family: $font-family-sans-serif-2;
    min-width: initial;
    max-width: none;
    font-size: 18px;
    width: auto;
    margin-bottom: 0;            
  }

  .getcustomerinfo-inner-wrapper {
      display: block;
      float: left;
      padding: 0;
      background-color: transparent;
      border-top: none;
      border-bottom: none;
      margin-bottom: none;
      width: 75%;
      margin-top: 30px;
      @media #{$medium-down}{
        width: 100%;
      }
  }

  .getcustomerinfo-button{
    background-color: black;
    border-color: black;
    position: relative;
    top: 2px;
  }

  .country-selector-indicator-flag-wrapper{
    display: none;
  }

  .customer-info-country-selector-wrapper{
    margin-left: 0;
  }

  #ctl00_main_responsivecheckout_CustomerInfo_divEmail{
    clear: both;
  }    

  // Big checkout button
  .form-button.form-button-checkout {
    background-color: $js-checkout-checkout-background-color;
    &:focus {
      background-color: darken($js-checkout-checkout-background-color, $js-checkout-form-button-factor);
    }
  }

  // Country selector - Active item
  .country-selector-item-link.country-selector-item-link-selected {
    background-color: $js-checkout-country-selector-item-active-background-color;
    color: $js-checkout-country-selector-item-active-color;
  }

  // Payment selector - Active item
  .payment-selector-wrapper {
    .payment-selector-item.payment-selector-item-selected, .payment-selector-item-link.payment-selector-item-link-selected {
      background-color: $js-checkout-payment-selector-item-active-background-color;
      color: $js-checkout-payment-selector-item-active-color;
      .payment-selector-item-options {
        background-color: lighten($js-checkout-payment-selector-item-active-background-color,5%);
        //border-top: 1px solid darken($js-checkout-payment-selector-item-active-background-color, 10%);
      }
    }
  }

  // Freight selector - Active item
  .freight-selector-wrapper {
    .freight-selector-item {
      .freight-selector-item-link.aspNetDisabled {
        background-color: $js-checkout-freight-selector-item-active-background-color;
        color: $js-checkout-freight-selector-item-active-color;
      }
    }
  }

  //
  //  Free Freight Message
  //
  /*
  .responsive-free-freight.responsive-free-freight-placement-cartsummary,
  .responsive-free-freight.responsive-free-freight-placement-orderconfirmation {
    color: $js-checkout-freefreight-background-color;
    background-color: lighten($js-checkout-freefreight-background-color, 45%);
    border: 1px solid lighten($js-checkout-freefreight-background-color, 35%);

    &.responsive-free-freight-limit-reached {
      background-color: lighten($js-checkout-freefreight-reached-background-color, 50%);
      border: 1px solid lighten($js-checkout-freefreight-reached-background-color, 15%);
      color: $js-body-font-color;
    }
  }
  */
}

