//
//  SETTINGS
//

//
//  Category Navigation - Medium down
//
$js-cat-nav-show-lv2: true;
$js-cat-nav-show-lv3: true;
$js-cat-nav-show-lv4: true;

$js-cat-nav-small-background-color: #fff;
$js-cat-nav-small-padding: 2px 10px 2px 10px;;
$js-cat-nav-small-left: 10px;
$js-cat-nav-small-box-shadow: 0 0 10px rgba(0,0,0,0.5);
$js-cat-nav-small-border: 1px solid #000;

// LV1
$js-cat-nav-small-lv1-item-background-color: transparent;
$js-cat-nav-small-lv1-item-margin: 0;
$js-cat-nav-small-lv1-item-padding: 10px 0;
$js-cat-nav-small-lv1-item-border-top: none;
$js-cat-nav-small-lv1-item-border-right: none;
$js-cat-nav-small-lv1-item-border-bottom: none;
$js-cat-nav-small-lv1-item-border-left: none;
$js-cat-nav-small-lv1-item-separator-border: 1px solid #ccc;
$js-cat-nav-small-lv1-link-padding: 0 0 0 0px;
$js-cat-nav-small-lv1-link-margin: 0 35px 0 0;
$js-cat-nav-small-lv1-link-font-weight: bold;
$js-cat-nav-small-lv1-link-font-family: $font-family-header;
$js-cat-nav-small-lv1-link-font-size: 15px;
$js-cat-nav-small-lv1-link-line-height: 1.3;
$js-cat-nav-small-lv1-link-color: #000;
$js-cat-nav-small-lv1-link-text-decoration: none;

$js-cat-nav-small-lv1-subcat-indicator-font-size: 30px;
$js-cat-nav-small-lv1-subcat-indicator-content: "\f055";
$js-cat-nav-small-lv1-subcat-indicator-open-content: "\f056";
$js-cat-nav-small-lv1-subcat-indicator-color: #000;
$js-cat-nav-small-lv1-subcat-indicator-right: 0;
$js-cat-nav-small-lv1-subcat-indicator-top: 4px;

// LV2
$js-cat-nav-small-lv2-container-padding: 5px 0 0 10px;
$js-cat-nav-small-lv2-item-background-color: transparent;
$js-cat-nav-small-lv2-item-margin: 0 10px 0 0;
$js-cat-nav-small-lv2-item-padding: 0 0px 0 0;
$js-cat-nav-small-lv2-item-border-top: none;
$js-cat-nav-small-lv2-item-border-right: none;
$js-cat-nav-small-lv2-item-border-bottom: none;
$js-cat-nav-small-lv2-item-border-left: none;
$js-cat-nav-small-lv2-link-padding: 7px 0 7px 0px;
$js-cat-nav-small-lv2-link-margin: 0 35px 0 0 ;
$js-cat-nav-small-lv2-link-font-weight: normal;
$js-cat-nav-small-lv2-link-font-family: $font-family-header;
$js-cat-nav-small-lv2-link-font-size: 14px;
$js-cat-nav-small-lv2-link-line-height: 1.3;
$js-cat-nav-small-lv2-link-color: #000;
$js-cat-nav-small-lv2-link-text-decoration: none;

$js-cat-nav-small-lv2-subcat-indicator-font-size: 28px;
$js-cat-nav-small-lv2-subcat-indicator-content: "\f055";
$js-cat-nav-small-lv2-subcat-indicator-open-content: "\f056";
$js-cat-nav-small-lv2-subcat-indicator-color: #333;
$js-cat-nav-small-lv2-subcat-indicator-right: 0;
$js-cat-nav-small-lv2-subcat-indicator-top: 2px;

// LV3
$js-cat-nav-small-lv3-container-padding: 0px 0 5px 10px;
$js-cat-nav-small-lv3-item-background-color: transparent;
$js-cat-nav-small-lv3-item-margin: 0 10px 0 0;
$js-cat-nav-small-lv3-item-padding: 0 0px 0 0;
$js-cat-nav-small-lv3-item-border-top: none;
$js-cat-nav-small-lv3-item-border-right: none;
$js-cat-nav-small-lv3-item-border-bottom: none;
$js-cat-nav-small-lv3-item-border-left: none;
$js-cat-nav-small-lv3-link-padding: 7px 0 7px 0px;
$js-cat-nav-small-lv3-link-margin: 0 35px 0 0 ;
$js-cat-nav-small-lv3-link-font-weight: normal;
$js-cat-nav-small-lv3-link-font-family: $font-family-header;
$js-cat-nav-small-lv3-link-font-size: 14px;
$js-cat-nav-small-lv3-link-line-height: 1.3;
$js-cat-nav-small-lv3-link-color: #000;
$js-cat-nav-small-lv3-link-text-decoration: none;

$js-cat-nav-small-lv3-subcat-indicator-font-size: 28px;
$js-cat-nav-small-lv3-subcat-indicator-content: "\f055";
$js-cat-nav-small-lv3-subcat-indicator-open-content: "\f056";
$js-cat-nav-small-lv3-subcat-indicator-color: #666;
$js-cat-nav-small-lv3-subcat-indicator-right: 0;
$js-cat-nav-small-lv3-subcat-indicator-top: 2px;

// LV4
$js-cat-nav-small-lv4-container-padding: 0px 0 5px 10px;
$js-cat-nav-small-lv4-item-background-color: transparent;
$js-cat-nav-small-lv4-item-margin: 0 30px 0 0;
$js-cat-nav-small-lv4-item-padding: 0 0px 0 0;
$js-cat-nav-small-lv4-item-border-top: none;
$js-cat-nav-small-lv4-item-border-right: none;
$js-cat-nav-small-lv4-item-border-bottom: none;
$js-cat-nav-small-lv4-item-border-left: none;
$js-cat-nav-small-lv4-link-padding: 7px 0 7px 0px;
$js-cat-nav-small-lv4-link-margin: 0 0px 0 0 ;
$js-cat-nav-small-lv4-link-font-weight: normal;
$js-cat-nav-small-lv4-link-font-family: $font-family-header;
$js-cat-nav-small-lv4-link-font-size: 14px;
$js-cat-nav-small-lv4-link-line-height: 1.3;
$js-cat-nav-small-lv4-link-color: #000;
$js-cat-nav-small-lv4-link-text-decoration: none;
