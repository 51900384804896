
/* PRODUCT PAGE
----------------------------------------------------------------------------- */

#stock-notification-popup {
  height: auto;

  * {
    box-sizing: border-box;
  }
}

#fb-wrapper {
  display: none;
}

.Gilla {
  display: none;
}

.page-showproduct #main-area {
  border: $uc-color 10px solid;
  background: $white;
}

.page-showproduct {
  .fancybox-iframe {
    html, body {
      height: 100%;
    }
  }
}


.product-images {
  float: left;
  width: 50%;
  margin: 0 0 10px 0;
  padding-right: 30px;
}

.product-images.original{
  @media #{$medium-down}{
    display: none;
  }
}
.product-images.slider{
  @media #{$large-up}{
    display: none;
  }
}

.product-description {
  float: right;
  width: 50%;
  margin: 0 0 10px 0;
  clear: right;
}

#FrontImage {
  max-width: 100%;
  cursor: pointer;
  vertical-align: bottom;
}

.show-product-image {
  position: relative;
}

a.ProductThumbnail {
//  overflow: hidden;
//  width: 20%;
//  float: left;
}

a.ProductThumbnail img {
//  margin: 10px;
}

.ProductThumbnailsBody {
  margin-top: 20px;
}
.ProductImageClickMessage {
  display: none;
}

h1.product-page-header {
  margin: 0 0 10px 0;
  display: inline-block;
  background: $black;
  color: white;
  padding: 5px 20px;
  font-family: $font-family-sans-serif-2;
  font-size: 40px;
}


h2.product-subname {
  margin: 0 0 10px 0;
  color: #999;
}

.product-short-description {
  @include js-description-text($js-description-font-size);
}

.tab-item.tab-description {
  @include js-description-text($js-description-font-size);
}


.product-short-description, .product-description .inner {
  overflow: hidden;
  margin: 0 0 5px 0;
}

.product-short-description {
  margin-bottom: 22px;
}

.product-description .inner div {
  margin: 0 0 5px 0;
}

span.product-article-number-label, span.product-unit-label, span.stock-status-label {
  font-weight: bold;
  margin: 0 5px 0 0;
}

// Hide article number
.product-article-number {
  display: none;
}

// Hide product unit
.product-unit {
  display: none;
}

.ProductCommentItemCssClass {
  margin: 0 0 20px 0;
}

.ProductCommentItemCssClass span {
  display: block;
  font-weight: bold;
}

.ProductCommentLargeInputCssClass {
  width: 100%;
  padding: 4px;
  border: 1px solid #cdcdcd;
  resize: vertical;
}


.product-specification-params {
  display: none;
}

.product-attributes {
  overflow: hidden;
  select{
    width: 220px;
    padding: 10px 10px;
  }
  /*
  select {
    -webkit-appearance: none;
    appearance: none;
    background-color: $black;
    color: white;
    @include border-radius(0px);
    padding: 4px 10px;
    border: none;
    margin-bottom: 8px;
    min-width: 120px;
    -webkit-user-select: none;
    outline: none;
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    content:"\f107";
    font-family: "FontAwesome";
    color: white;
    padding: 6px;
    position: relative;
    left: -30px;
    top: 0;
    background: transparent;
    text-align: center;
    pointer-events: none;
  }
  */
}
/*
body.browser-firefox {
  .product-attributes  {
    select {
      -moz-appearance: none;
      -moz-user-select: none;
      color: white;
      color: rgba(255,255,255,0);
      text-shadow: 0 0 0 white;
    }
  }
}
body.browser-internet-explorer {
  .product-attributes  {
    &:after {
      display: inline-block;
    }
  }
}
*/


.product-attributes-1 {
  margin: 0 0 5px 0;
  font-weight: bold;
}

.product-attributes-2 {
  margin: 0;
  font-weight: bold;
}

.product-attributes span {
  display: block;
  margin: 0 0 3px 0;
}


span.product-specification-label {
  font-weight: bold;
  display: block;
  margin: 0 0 2px 0;
}

.product-specification-wrapper {
  //display: none;
  //margin-bottom: 24px;
}

.product-specification-wrapper select {
  min-width: 190px;
  margin: 0 0 10px 0;
}

.purchase-block {
  clear: both;
}

.page-showproduct .purchase-block-price {
  margin: 15px 0 5px;
}

.page-showproduct .purchase-block-price-currency {
  padding: 0 0 0 5px;
}

.page-showproduct .purchase-block-price-unit {
  display: none;
}

.purchase-block-price .KlarnaMonthlyCost {
  margin: 5px 0 0 0;
}

.purchase-block-quantity {
  float: left;
  margin: 0 10px 0 0;
}

.purchase-block-quantity span {
  display: none;
}

.purchase-block-quantity input {
  width: 36px;
  height: 35px;
  padding: 0;
  text-align: center;
}

.purchase-block-buy a.buy-button {
  font-size: $js-button-buy-product-page-font-size;
  text-align: center;
  margin: 0;
  width: 140px;

}
.purchase-block-price-previous-unit{
  display: none;
}
.purchase-block-buy a.buy-button:hover {
  font-size: $js-button-buy-product-page-font-size;
}

.product-payment {
  width: 320px;
  text-align: right;
  float: right;
  margin-top: -32px;
  @media #{$medium-down} {
    float: none;
    margin-top: 20px;
    width: auto;
    text-align: center;
  }
  .payment-item {
    display: inline-block;
    vertical-align: middle;
    img {
      max-height: 35px;
      @media #{$small-only} {
        max-height: 30px;
      }
    }
  }
}


/* TEMPLATES */
.template-wrapper .templateRow,
.template-wrapper .templateAlternateRow
{
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 10px 10px 0;
}


.templateItemTitle {
  display: inline-block;
  width: 150px;
  font-weight: bold;
  vertical-align: top;
}

.templateItemData {
  display: inline-block;
  width: 150px;
  vertical-align: top;
}

/* RELATED PRODUCTS */
table.relatedTable tr {
  border-bottom: 1px solid #dfdfdf;
}

table.relatedTable tr:first-child, table.relatedTable tr:last-child {
  border-bottom: 0;
}

table.relatedTable tr.headerGray {
  display: none;
}

table.relatedTable td {
  vertical-align: middle;
  padding: 10px 10px 10px 0;
}

td.RelatedProductImage {
  width: 30%;
  text-align: left !important;
}

td.RelatedProductName {
  width: 30%;
}

td.RelatedProductName a {
  font-weight: bold;
}

td.RelatedProductArticleNumber {
  display: none;
}

td.RelatedProductPrice {
  width: 20%;
}

td.RelatedProductButtons {
  width: 20%;
}

td.RelatedProductButtons a {
  float: right;
}

td.RelatedProductButtons br {
  display: none;
}

/* TOOLBAR */
.product-toolbar {
  @include clearfix;
  clear: both;
  //display: inline-block;
  display: none;
  margin: 0 10px 10px 0;
  //padding: 10px;
  //background: #efefef;
  //border-top: 1px solid #cdcdcd;
  //behavior: url(/SystemScripts/PIE.htc);
}
.AddThisScript{
  margin-top: 30px !important;
}
.product-toolbar img {
  width: 75%;
}

.product-toolbar-image-wrapper {
  float: left;
  margin: 0 15px 0 0;
  cursor: pointer;
}

.product-toolbar-image-wrapper.product-toolbar-print,
.product-toolbar-image-wrapper.product-toolbar-showimage,
.product-toolbar-image-wrapper.product-toolbar-pdf
{
  display: none;
}

.social-media {
  float: right;
}

.product-page-back-link {
  display: none;
  clear: both;
  float: left;
  cursor: pointer;
  margin: 10px;
  font-weight: bold;
}

/* PRODUCT FILES */
.product-files-box {
  background: white;
  border: 1px solid #D8D8D8;
  padding: 10px;
  text-align: left;
  width: 300px;
  min-width: 300px;
  border: solid 1px rgb(180, 180, 180);
}

.product-files-box-shadow {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  opacity: 0.15;
  filter: alpha(opacity=15);
}

.product-files-link {
  line-height: 21px;
  padding-left: 5px;
}

.product-files-button {
  float: left;
}

.product-files-box-close {
  min-width: 30px;
  min-height: 30px;
  text-decoration: underline;
  cursor: pointer;
  background: url(/images/fancybox/fancy_closebox.png) no-repeat;
  height: 30px;
  width: 30px;
  float: right;
}

.product-files-header {
  font-weight: bold;
}


div.product-tabs {
  margin: 0;
}

//
//  Tab system
//

#tab-wrapper {
  clear: both;
}

.tab-item {
  margin-bottom: 44px;
  &:first-child {
    margin-top: 44px;
  }
  .tab-item-header {
    font-size: 1.4em;
    padding-bottom: 3px;
    border-bottom: 1px dotted #cdcdcd;
  }
  .tab-item-inner {
    padding-bottom: 8px;
  }
}


//
//  Errors on product comment validation
//
.ProductCommentValidatorErrorCssClass {
  color: $alert-color;
  background-color: lighten($alert-color, 50%);
  padding: 5px;
  &:before {
    font-family: "FontAwesome";
    content: "\f071";
    padding-right: 6px;
  }
}


//
//  Small
//
@media #{$small-only} {

  .product-images {
    float: none;
    width: 100%;
    padding-right: 0;
  }

  .product-description {
    float: none;
    width: 100%;
    clear: both;
  }
}