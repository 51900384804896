/* CART AREA
----------------------------------------------------------------------------- */
.cart-area-wrapper {
  float: right;
  min-width: $js-cart-area-wrapper-min-width;
  max-width: $js-cart-area-wrapper-max-width;
  margin: $js-cart-area-wrapper-margin;
  background-color: $js-cart-area-wrapper-background-color;
  border: $js-cart-area-wrapper-border;
  min-height: $js-cart-area-wrapper-min-height;
  position: relative;
}

.small-cart-wrapper {
  overflow: hidden;
  text-align: left;
  border: $js-small-cart-border;
  background: transparent;
}

.small-cart-header {
  display: none;
}

.small-cart-body {
  float: left;
  padding: 0 $js-small-cart-padding-right 0 $js-small-cart-padding-left;
  cursor: pointer;
  transition: background 0.3s ease-out;
  color: $js-small-cart-color;
  
  &:hover,
  &:hover + * {
    background: $js-cart-area-wrapper-background-hover-color;
  }

  // Icon
  &::before {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    content: "\f07a";
    font-size: $js-small-cart-icon-size;
    float: left;
    display: block;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    margin-right: $js-small-cart-icon-margin-right;
  }

  .title {
    display: none;
  }

}

.small-cart-items {
  float: left;
  height: $js-small-cart-height;
  line-height: $js-small-cart-height;

  // Number of items
  .small-cart-items-value {
    display: none;
    margin-right: $js-small-cart-items-value-margin-right;
    font-size: $js-small-cart-font-size;
    float: left;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;

    &::before {
      content: "(";
    }

    &::after {
      content: ")";
    }
  }

  .small-cart-items-label,
  .small-cart-items-suffix {
    display: none;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    font-size: $js-small-cart-font-size;
  }
}

.small-cart-sum {
  float: left;
  height: $js-small-cart-height;
  font-size: $js-small-cart-font-size;
  line-height: ($js-small-cart-height - 1);

  &::after {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    content: "\f107";
    font-size: 16px;
    float: left;
    display: block;
    height: $js-small-cart-height;
    line-height: ($js-small-cart-height + 2);
    margin-left: 8px;
    margin-right: 12px;
  }

  // Label "Total sum"
  .small-cart-sum-label {
    display: none;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    font-size: $js-small-cart-font-size;
  }

  // Total sum
  .small-cart-sum-value {
    float: left;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    font-size: $js-small-cart-font-size;
    font-weight: normal;
  }
}

.SmallCartButtons {
  float: right;
  height: $js-small-cart-height;
  transition: background 0.3s ease-out;
}

.SmallCartButtons div {
}

.CheckoutButton, .MyPagesButton {
  margin: 0 10px 0 0;
}

.small-cart-wrapper .KlarnaMonthlyCost {
  clear: both;
  padding: 2px 0;
  font-size: 11px;
  text-align: center;
  border-top: 1px dashed #dfdfdf;
}

#FreeFreight_Cart {
  //min-height: 21px;
}

.FreeFreight_Cart {
  background: $js-cart-freefreight-background;
  //border-top: $js-cart-freefreight-border-top;
  cursor: default;
  padding: 2px 0;
  text-align: center;
  font-size: 11px;
  max-height: 30px;
  overflow: hidden;
}

.FreeFreight_Cart_reached {
  background: $js-cart-freefreight-reached-background;
  //border-top: $js-cart-freefreight-reached-border-top;
  cursor: default;
  padding: 2px 0;
  text-align: center;
  font-size: 11px;
  max-height: 30px;
  overflow: hidden;
}

a.login-text.button {
  display: none;
}

// Fulhack to hide the buttons before we move them
a.login-text.button,
a.mypages-text.button {
  display: none;
}


//
//  Medium Down
//
@media #{$medium-down} {


  .cart-area-wrapper {
    float: right;
    margin: 0;
    min-width: 0;
    max-width: none;
    padding: 0;

    .small-cart-body {
      border: none;
      color: $js-menu-item-color;
      height: $js-menu-item-height;
      padding: $js-menu-buttons-padding;
      padding-right: $js-page-padding-right;
      cursor: pointer;

      &:hover {
        background-color: $js-menu-buttons-wrapper-background-hover-color;
      }

      &::before {
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;
        font-size: $js-menu-buttons-medium-icon-size;
      }

      .title {
        float: left;
        display: block;
        height: $js-menu-item-height;
        line-height: $js-menu-item-height + 2;
        font-size: $js-menu-buttons-font-size;
        font-family: $js-menu-buttons-font-family;
        text-transform: $js-menu-text-transform;
        padding: 0 0 0 2px;
        color: $js-menu-item-color;
      }
    }

    .small-cart-items {
      height: $js-menu-item-height;
      line-height: $js-menu-item-height;

      .small-cart-items-value {
        //height: $js-menu-item-height;
        line-height: 17px;
        font-family: $js-menu-buttons-font-family;
        color: $js-menu-item-color;
        padding: 0 5px;
        background-color: white;
        color: $black;
        border-radius: 10px;
        min-width: 16px;
        min-height: 16px;
        max-height: 16px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 5px;
        text-align: center;
        font-size: 11px;
        float: none;
        height: auto;


        &::before, &::after {
          display: none;
        }
      }
    }

    .small-cart-sum {
      height: $js-menu-item-height;
      display: none;

      .small-cart-sum-value {
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;
        font-size: $js-menu-buttons-font-size;
        font-weight: normal;
        text-transform: $js-menu-text-transform;
      }
    }

    .SmallCartButtons {
      display: none;
    }

    #FreeFreight_Cart {
      display: none;
    }
  }
}
//
//  Medium
//
@media #{$medium-only} {


  .small-cart-body .title {
    display: block;
  }
}

//
//  Small
//
@media #{$small-only} {
  .cart-area-wrapper {
    .small-cart-body {
      &::before {
        font-size: $js-menu-buttons-small-icon-size;
      }
    }
  }
}