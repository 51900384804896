//
//    Culture & Currency selector
//
.culture-selector-wrapper {
  //margin-top: 20px;
}

.culture-selector-wrapper, .currency-selector-wrapper {
  float: right;
  clear: right;

  select {
    -webkit-appearance: none;
    appearance: none;
    background-color: $black;
    color: white;
    @include border-radius(0px);
    padding: 4px 10px;
    border: none;
    margin-bottom: 8px;
    min-width: 120px;
    -webkit-user-select: none;
    outline: none;
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    content:"\f107";
    font-family: "FontAwesome";
    color: white;
    padding: 6px;
    position: relative;
    left: -30px;
    top: 0;
    background: transparent;
    text-align: center;
    pointer-events: none;
  }
}
body.browser-firefox {
  .culture-selector-wrapper, .currency-selector-wrapper  {
    select {
      -moz-appearance: none;
      -moz-user-select: none;
      color: white;
      color: rgba(255,255,255,0);
      text-shadow: 0 0 0 white;
    }
  }
}
body.browser-internet-explorer {
  .culture-selector-wrapper, .currency-selector-wrapper  {
    &:after {
      display: inline-block;
    }
  }
}

//
//  VAT selector
//
.vat-selector-outer-wrapper {
  float: right;
  clear: right;

  .vat-selector-wrapper {
    float: none;
    font-size: $js-body-font-size;
    .vat-selector-label {
      span {
        font-size: $js-body-font-size;
      }
    }
  }
}


//
//  Newsletter Box
//
.newsletter-box-wrapper {
  display: none;
}

.newsletter-box-header {
  background: none;
  border: 0;
  padding: 0;
  text-align: right;
  margin-bottom: 0px;

  span {
    // Make this behave like a h2
    font-size: $h3-font-size;
    text-transform: $font-transform-header;
    color: $header-font-color;
    font-family: $header-font-family;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
    line-height: $header-line-height;
    margin-bottom: $header-bottom-margin;
    margin-top: $header-top-margin;
    text-rendering: $header-text-rendering;
  }
}

// Add the envelope icon after newsletter header
/*
.newsletter-box-header span:after {
  font-family: FontAwesome;
  content: "\f003";
  padding-left: 6px;
}
*/

.newsletter-box-body {
  background: none;
  border: 0;
  padding: 0;
  float: right;
  text-align: right;

  a {
    margin: 0 !important;
  }
}

.newsletter-box-body:hover {
  background: none;
}

.newsletter-box-text {
  display: none;
}

.newsletter-box-radio {
  float: right;
  clear: left;
}

.newsletter-box-radio-subscribe {
  float: left;
  margin: 0 10px 10px 0;

  label {
    display: inline-block;
    margin-left: 6px;
  }
}

.newsletter-box-radio-unsubscribe {
  float: left;
  margin: 0 0 10px 0;

  label {
    display: inline-block;
    margin-left: 6px;
  }
}

.newsletter-box-input {
  float: left;
  clear: right;
  width: 260px;
  //max-width: 180px;
  margin: 0 6px 0 0;
}

.newsletter-box-input input {
  border: none;
  color: $js-footer-color;
  width: 100%;
  max-width: 180px;
  height: 30px;
  line-height: 28px;
  background-color: $white;
  padding: 0 5px 0 10px;
  font-family: $font-family-sans-serif-regular;
  font-size: 0.9em;
}

.newsletter-box-button {
  float: right;
  a {
    padding-top: 6px;
    padding-bottom: 6px;
    color: $button-font-color !important;
  }
}

.newsletter-box-footer {
  background: none;
}


//
//    Footer
//
#footer {
  background-color: $uc-color;
  border-top: 1px solid $uc-color;
  padding-top: 40px;
  padding-bottom: 40px;
  color: $js-footer-color;

  a {
    display: inline-block;
    color: $js-footer-color;
    background: $black;
    vertical-align: center;
    font-family: $font-family-sans-serif;
    padding: 0 8px;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
    margin-top: 5px;
  }
  a:hover {
    //color: darken($js-footer-color,10%);
    text-decoration: none;
  }
}

.footer-lower {
  overflow: hidden;
  width: 100%;
  max-width: $js-max-page-width;
  margin: 0 auto;
  padding: 0 $js-page-padding-right 0 $js-page-padding-left;
}

#jetshop-branding {
  background-color: black;
  padding: 20px;
  clear: both;
}
.infoTextLogo {
  clear: both;
  width: 143px;
  margin: 30px auto;
}

.footer-box {
  padding-right: 30px;
  padding-bottom: 0px;
  float: left;
  width: auto !important;
  &:last-child{
    float: right;
    padding-right: 0;
    h2{
      float: right;
    }
    @media #{$small-only} {
      width: 100% !important;
      float: left;
      h2, .culture-selector-wrapper, .currency-selector-wrapper, .vat-selector-outer-wrapper{
        float: left;
        clear: both;
      } 
    }    
  }
  h2, .newsletter-box-header span {
    display: inline-block;
    padding: 1px 8px;
    font-family: $font-family-sans-serif-2;
    font-size: 20px;
    margin-bottom: 16px;
    color: $black;
    background: white;
  }
  p {
    margin-bottom: 2px;
  }
  h2 {
    i {
      padding-left: 8px;
    }
  }
}

.footer-payment {
  .payment-item {
    background: #fff;
    float: left;
    clear: both;
    margin-bottom: 8px;
    padding: 10px;
    @include clearfix();
  }
}

.page-list-wrapper{
  background: white;
  padding: 15px; 
}

#footer.footer-box-count-1 .footer-box{
  width: 100%;
}

#footer.footer-box-count-2 .footer-box{
  width: 50%;
}

#footer.footer-box-count-3 .footer-box{
  width: 33%;
}

#footer.footer-box-count-4 .footer-box{
  width: 25%;
}

#footer.footer-box-count-5 .footer-box{
  width: 20%;
}

#footer.footer-box-count-6 .footer-box{
  width: 16.66%;
}


//
//  Small
//
@media #{$small-only} {
  #footer .footer-box {
    padding-bottom: 22px;
  }

  #footer.footer-box-count-3 .footer-box {
    width: 100%;
  }

  #footer.footer-box-count-4 .footer-box {
    width: 100%;
  }

  #footer.footer-box-count-5 .footer-box {
    width: 100%;
  }

  #footer.footer-box-count-6 .footer-box {
    width: 100%;
  }
}

//
//  Medium
//
@media #{$medium-only} {
  #footer .footer-box {
    padding-bottom: 22px;
  }

  #footer.footer-box-count-3 .footer-box{
    width: 33%;
  }

  #footer.footer-box-count-4 .footer-box{
    width: 50%;
  }

  #footer.footer-box-count-5 .footer-box{
    width: 50%;
  }

  #footer.footer-box-count-6 .footer-box{
    width: 50%;
  }
}