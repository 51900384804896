//
//  Header
//

#header {
  clear: both;
  position: relative;
  width: 100%;
  margin: 0;
  padding-left: 40px;
  padding-right: 40px;
  background-color: $js-header-background-color;
  @include clearfix();
}

#header-inner {
  max-width: $js-max-page-width;
  margin: 0 auto;
  @include clearfix();
}

.header-scrolled #menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  border-bottom: 1px solid #ccc;
}

.header-scrolled.header-static #menu-wrapper {
  top: -120px; //TODO WHY HARDCODED HEIGHT?
  opacity: 0;
  //transition: opacity 0s, top 0s;
}

.header-static #menu-wrapper {
  opacity: 1;
  //transition: opacity 0.3s;
}

.logo-wrapper {
  float: left;
  margin: $js-logo-wrapper-margin;

  .logo-header {
    display: none;
  }

  .logo-body {
    max-width: $js-logo-wrapper-max-width;
    line-height: 0;
  }

  input {
    height: 50px;
    outline: none;
    max-width: 100%;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
  }

  .logo-footer {
    display: none;
  }
}

.header-scrolled .logo-wrapper {
}

//
//  Activator icons
//
#menu-content {

  .icon-button {
    display: none
  }

}

//
//  Sub Navigation / Breadcrumbs
//
#path-nav {
  display: block;
  left: 0;
  width: 100%;
  max-width: $js-max-page-width;
  padding-left: $js-page-padding-left;
  padding-right: $js-page-padding-right;
  padding-top: 10px;
  padding-bottom: 2px;
  font-size: $js-breadcrumb-font-size;
  margin: 0 auto;

  .breadcrumb-link {
    display: inline-block;
    padding: 10px 15px 8px 15px;
    color: $js-breadcrumb-font-color;
    background: $white;
  }

  :first-child {
    padding: 10px 15px 8px 20px;
  }

  :last-child {
    padding: 10px 20px 8px 15px;
  }

  .breadcrumb-spacer {
    display: inline-block;
    padding: 10px 0px 8px 0px;
    color: $js-primary-link-color;
    background: $white;
  }
}

//
//  Search Box
//

.search-box-wrapper {
  font-size: 14px;
  padding: $js-search-box-wrapper-padding;
  margin: $js-search-box-wrapper-margin;
  float: left;
  background-color: $js-search-box-wrapper-background-color;
}

.search-box-header {
  display: none;
}

.search-box-body {
  overflow: hidden;
  height: auto;
  line-height: 32px;
}

.search-box-input {
  float: left;
}

.search-box-input input {
  border: 2px solid $black;
  width: $js-search-box-input-width;
  background-color: $js-search-box-input-background-color;
  padding: $js-search-box-input-padding;
  font-family: $font-family-sans-serif-regular;
  font-size: $js-search-box-input-font-size;
  outline: none;
  color: $js-search-box-input-color;
  @include border-radius(0);
  -webkit-appearance: none;
  display: block;
}

.search-box-input input:focus {
  background: $js-search-box-input-background-color-focus;
  color: $js-body-font-color;
}

.search-box-button {
  float: left;
  margin-top: 4px;
  position: relative;
  left: -27px;
  a {
    padding: 2px 0px;
  }
  img {
    width: 21px;
  }
}

/* MARQUEE
----------------------------------------------------------------------------- */
.marquee {
  margin: 0;
  display: none;
}

//
//  Medium Down
//
@media #{$medium-down} {

  #menu-content {
    display: block;
    background-color: $js-menu-buttons-wrapper-background-color;
    clear: both;
    float: none;
    width: 100%;
    @include clearfix();

    .icon-button {
      font-size: $js-menu-buttons-font-size;
      font-family: $js-menu-buttons-font-family;
      text-transform: $js-menu-text-transform;
      padding: $js-menu-buttons-padding;
      color: $js-menu-item-color;
      cursor: pointer;
      float: left;
      display: block;
      height: $js-menu-item-height;
      line-height: $js-menu-item-height;

      i {
        margin-right: 4px;
        font-size: $js-menu-buttons-medium-icon-size;
        float: left;
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;

        &:before {
        }
      }

      span {
        float: left;
        height: $js-menu-item-height;
        line-height: $js-menu-item-height + 2;
      }

      &:hover {
        background-color: $js-menu-buttons-wrapper-background-hover-color;
      }
    }

    #cart-activator {
      display: none !important;
    }

    #menu-activator.icon-button.menu-icon {
      padding-left: ($js-page-padding-left - 4px);
      border-right: 1px solid darken($js-menu-buttons-wrapper-background-color, 10%);
    }
  }

  //
  // SCROLLED - NOT SCROLLED DEFINITIONS
  //

  #menu-wrapper {
    opacity: 0;
    clear: both;
    width: 100%;
  }

  .menu-static #menu-wrapper {
    opacity: 1;
    transition: opacity 0.3s;
    clear: both;
    width: 100%;
  }

  .menu-scrolled #menu-wrapper {
    position: fixed;
    left: 0px;
    opacity: 1;
    transition: opacity 0.3s, top 0.3s;
    top: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  .menu-scrolled.menu-static #menu-wrapper {
    top: -100px;
  }

  #menu-buttons-wrapper {
    display: block;
  }

  .vat-selector-wrapper .vat-selector-label span {
    font-size: 1em;
  }

  .logo-wrapper {
    float: none;
    display: block;
    width: auto;
    margin: $js-logo-wrapper-margin-medium;

    .logo-body {
      width: auto;
      margin: 0 auto;
      max-width: 75px;
    }

    input {
      height: 40px;
    }
  }

  .menu-component {
    //@include box-shadow(0px,3px,3px, 0px,rgba(0, 0, 0, 0.24));
  }

  // SEARCH
  #search-box-wrapper {
    float: left;
    vertical-align: middle;
    padding: $js-search-box-wrapper-medium-padding;
    margin: $js-search-box-wrapper-medium-margin;
    background-color: $js-search-box-wrapper-medium-background-color;
    height: $js-menu-item-height;
    line-height: $js-menu-item-height;
  }
  .search-box-wrapper {
    padding: 0 0 0 5px;
    float: left;
    margin: 0;
    max-width: 0;
    overflow: hidden;
    transition: 0.3s;
    height: $js-menu-item-height;
    line-height: $js-menu-item-height;
    opacity: 0;

    .search-box-header,
    .search-box-footer {
      display: none;
    }

    .search-box-body {
      height: $js-menu-item-height;
      line-height: $js-menu-item-height;

      .search-box-input {
        float: none;
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;
        //margin-top: 1px;

        input {
          width: $js-search-box-input-medium-width;
          background-color: $js-search-box-input-medium-background-color;
          padding: $js-search-box-input-medium-padding;
          font-size: $js-search-box-input-medium-font-size;
          background-position: $js-search-box-input-medium-background-position;
          border-color: $iron;
          border: none;
          color: $js-body-font-color;
          height: ($js-menu-item-height - 10);
          display: inline-block;

          &.watermark {
            color: rgba(0, 0, 0, 0);
          }

          &:focus {
            background-color: $js-search-box-input-medium-background-color;
          }
        }
      }

      .search-box-button {
        display: none;
      }
    }
  }

  .search-open {
    #search-box-wrapper {
      background: $js-menu-buttons-wrapper-background-hover-color;
    }
    .search-box-wrapper {
      max-width: 100%;
      opacity: 1;
    }
  }
}

//
//  Small
//
@media #{$small-only} {

  #header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .search-open {
    #search-box-wrapper {
      background: $js-menu-buttons-wrapper-background-hover-color;
    }
    .search-box-wrapper {
      max-width: 100%;
      opacity: 1;
    }
  }

  .logo-wrapper {
    float: none;
    display: block;
    width: auto;
    margin: $js-logo-wrapper-margin-small;

    .logo-body {
      width: auto;
      margin: 0 auto;
      max-width: 75px;
    }

    input {
      height: 40px;
    }
  }

  #menu-content {
    .icon-button {
      i {
        font-size: $js-menu-buttons-small-icon-size;
        margin: 0;
      }

      span {
        display: none;
      }
    }

    .small-cart-body .title {
      display: none !important;
    }
  }

  #path-nav {
    padding-left: 20px;
    padding-right: 20px;    
  }
}