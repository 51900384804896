

.category-page-wrapper {
  font-size: 0;
}

.category-page-wrapper > * {
  font-size: $js-body-font-size;
}

.category-page-wrapper, .releware-recommendation-wrapper, #startpage_list, #list-category-placeholder {

  a.disabled {
    opacity: 0.5;
  }

  a.disabled:hover {
    background: #efefef;
    cursor: default;
  }

  ul {

    li {
      display: inline-block;
      vertical-align: top;
      padding: $js-product-wrapper-padding;
      background: #fff;
      text-align: center;
      border: 15px solid $uc-color;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.35);

      .product-image {
        margin: $js-product-wrapper-image-margin;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: $js-product-wrapper-image-wrapper-height;
        a {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          img {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-height: 100%;
            max-width: 100%;
            margin: auto;
          }
        }
      }

      .product-buttons,
      .product-buttons:hover {
        margin: 0 0 5px 0;
        overflow: hidden;
        a {
          display: inline-block;
          width: auto;
          background: $black;
          padding: 0 8px;
          font-size: 16px;
          line-height: 30px;
          &.buy-button{
            display: none;
          }
        }
      }

      .product-list-description {
        font-family: $js-description-font-family;
        font-size: $js-product-list-description-font-size;
        @include js-description-text($js-product-list-description-font-size);
        margin-top: 4px;
      }

      .product-name {
        clear: both;
        margin: 0 0 2px 0;
        word-wrap: break-word;

        h3 {
          @include product-name-h3
        }

        span.product-subname {
          font-size: $js-product-list-subname-font-size;
          color: $js-product-list-subname-color;
          display: none;
        }
      }

      .product-list-article-number span {
        color: $js-product-list-articlenumber-color;
        font-size: $js-product-list-articlenumber-font-size;
      }

      .product-price {
        .price-amount {
          font-size: $js-product-list-price-font-size;
          font-family: $js-product-list-price-font-family;
          a {
            color: $js-body-font-color;
          }
          a:hover {
            text-decoration: none;
          }
        }
      }

    }

  }

}

.category-header-wrapper {
  margin-bottom: 35px;
}

span.product-attrlist span {
  font-size: 0.85em;
  display: block;
}

.category-header-subtitle {
  @include js-description-text($js-description-font-size);
  & p {
    @include js-description-text($js-description-font-size);
  }
}

//
//  PAGING CONTROL
//
.category-page-wrapper .upper {
  display: none;
}

@mixin paging-link-style() {
  display: inline-block;
  font-size: $js-paging-control-link-font-size;
  font-family: $js-paging-control-link-font-family;
  text-decoration: none;
  font-weight: bold;
  color: $js-paging-control-link-color;
  background: $js-paging-control-link-background-color;
  padding: 5px 10px;
  border: $js-paging-control-link-border;
  @include border-radius($js-paging-control-link-border-radius);
  behavior: url(/SystemScripts/PIE.htc);
}

@mixin paging-link-hover-style() {
  color: $js-paging-control-link-hover-color;
  background: $js-paging-control-link-hover-background-color;
  border: $js-paging-control-link-hover-border;
}

// Upper paging
.paging-center-box {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.paging-control-box {
  background-color: $js-paging-control-box-background-color;
  padding: 3px 10px 3px 10px;
  margin-top: 1px;
  margin-bottom: 12px;
  border: $js-paging-control-box-border;
  width: $js-paging-control-box-width;
}

a.paging-link-box-selected,
a.paging-link.selected {
  @include paging-link-style;
  color: $js-paging-control-link-selected-color;
  background: $js-paging-control-link-selected-background-color;
  border: $js-paging-control-link-selected-border;
}

a.paging-link-box {
  @include paging-link-style;
}

a.paging-link-box:hover {
  @include paging-link-hover-style;
}

a.paging-spaceright {
  display: none;
  margin-right: 20px !important;
}

a.paging-spaceleft {
  display: none;
  margin-left: 20px !important;
  float: right;
}

// Hide the product-counter in upper
.paging-control-box span.showing {
  display: none;
}

// Lower paging
.paging-control {
  padding: 20px 10px 0 10px;
  text-align: center;
  clear: both;
}

a.paging-link {
  @include paging-link-style;
}

a.paging-link:hover {
  @include paging-link-hover-style;
}

a.paging-link.selected {
  color: $js-paging-control-link-selected-color;
  background-color: $js-paging-control-link-selected-background-color;
}

a.paging-link-previous {
  float: left;
  min-width: 80px;
}

a.paging-link-next {
  float: right;
  min-width: 80px;
}

#paging-control-switch {
  margin-top: 8px;
}

a.showall {
  color: $js-paging-control-showall-link-color;
  font-weight: bold;
  font-size: 9pt;
  text-align: right;
  cursor: pointer;
}

.paging-control-box.upper-box {
  display: none;
}


/* ------ Category filter ---------------------- */

.filter-control-body {
  padding: 0;
  border: solid 1px lighten($js-filter-control-background-color, 30%);
  width: 100%;
  background-color: lighten($js-filter-control-background-color, 40%);
  cursor: default;
  margin-bottom: 22px;
  @include clearfix();
  //@include border-radius($global-radius);

  select {
    max-width: 130px;
    min-width: 130px;
  }

}

.filter-control-header {
  padding: 12px;
  width: 120px;
  float: left;
  background-color: lighten($js-filter-control-background-color, 30%);
  font-style: italic;
  font-weight: bold;
  font-family: $body-font-family;
}

.filter-control-clear {
  padding-top: 1px;
  float: right;
}

.filter-control-options {
  float: left;
  @include clearfix();
}

.filter-control-element {
  float: left;
  padding: 12px;
  height: 20px;
  line-height: 10px;

  label {
    margin-left: 8px;
    display: inline-block;
  }
}

#filterprice {
  padding-left: 15px;
  padding-top: 4px;
  font-size: 90%;
}

#slider-pre {
  float: left;
  margin-right: 10px;
  margin-left: 20px;
  padding-top: 16px;
  font-size: 90%;
}

.price-filter-slider {
  width: 180px;
}

//
//  Sorting
//

.sort-wrapping {
  padding: 0;
  border: solid 1px lighten($js-sorting-control-background-color, 30%);
  width: 100%;
  background-color: lighten($js-sorting-control-background-color, 40%);
  cursor: default;
  margin-bottom: 22px;
  @include clearfix();
  @include border-radius($global-radius);
}

.sort-header {
  float: left;
  padding: 12px;
  width: 120px;
  background-color: lighten($js-sorting-control-background-color, 30%);
  font-style: italic;
  font-weight: bold;
  font-family: $body-font-family;
}

.sort-panel-wrapping {
  float: left;
}

.sort-panel {
  float: left;
  padding: 1px 8px;
  //border: 1px solid lighten($js-sorting-control-background-color, 30%);
  //@include border-radius($global-radius);
  margin-left: 8px;
  margin-top: 4px;
}

.sort-label {
  cursor: default;
  line-height: 30px;
  padding-left: 0px;
  padding-right: 3px;
  font-size: $base-font-size;
}

.sort-up,
.sort-down,
.sort-up-selected,
.sort-down-selected {
  padding: 1px 8px;
  border: 1px solid lighten($js-sorting-control-background-color, 20%);
  background-color: lighten($js-sorting-control-background-color, 50%);
  @include border-radius($global-radius);
  color: black;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
}

.sort-up:hover,
.sort-down:hover,
.sort-up-selected:hover,
.sort-down-selected:hover {
  text-decoration: none;
}

.sort-up {
  margin-right: 4px;
}

.sort-down {
}

.sort-up-selected {
  color: $js-sorting-control-selected-font-color;
  margin-right: 4px;
  border: 1px solid lighten($js-sorting-control-background-color, 0%);
  background-color: lighten($js-sorting-control-background-color, 10%);
}

.sort-down-selected {
  color: $js-sorting-control-selected-font-color;
  border: 1px solid lighten($js-sorting-control-background-color, 0%);
  background-color: lighten($js-sorting-control-background-color, 10%);
}


//
//  Small
//
@media #{$small-only} {
  .filter-control-element {
    width: 100%;
    margin: 0;
    float: left;
    margin-bottom: 12px;
  }
  .sort-panel {
    width: 100%;
    margin: 0;
    float: left;
    margin-bottom: 12px;
  }

  .category-page-image {
    width: 100%;
    clear: both;
  }
  @include calculate-item-width($js-total-products-small, $js-product-wrapper-margin-right, $js-product-wrapper-selector);
}

//
//  Medium
//
@media #{$medium-only} {
  .sort-panel {
    width: 33%;
    margin: 0;
    float: left;
    margin-bottom: 12px;
  }
  .filter-control-element {
    width: 33%;
    margin: 0;
    float: left;
    margin-bottom: 12px;
  }
  @include calculate-item-width($js-total-products-medium, $js-product-wrapper-margin-right, $js-product-wrapper-selector);
}

//
//  Medium Down
//
@media #{$medium-down} {
  .filter-control-header {
    width: 100%;
    clear: both;
  }
  .filter-control-options {
    width: 100%;
    padding-bottom: 12px;
  }
  .sort-header {
    width: 100%;
    clear: both;
  }
  .sort-panel-wrapping {
    width: 100%;
  }
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width($js-total-products-large, $js-product-wrapper-margin-right-large-up, $js-product-wrapper-selector);
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width($js-total-products-xlarge, $js-product-wrapper-margin-right-large-up, $js-product-wrapper-selector);
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width($js-total-products-xxlarge, $js-product-wrapper-margin-right-large-up, $js-product-wrapper-selector);
}



