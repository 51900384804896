//
//  Menu handling
//

  #menu-buttons-wrapper {
    display: none;
    //@include box-shadow(0px, 3px, 3px, 0px, rgba(0, 0, 0, 0.24));
  }
  #XXX {
  .icon-button {
    font-size: $js-menu-buttons-font-size;
    font-family: $js-menu-buttons-font-family;
    text-transform: $js-menu-text-transform;
    float: left;
    padding: $js-menu-buttons-padding;
    color: white;
    cursor: pointer;
    line-height: 0;
    display: none; 

    i {
      margin-right: 4px;
      font-size: $js-menu-buttons-medium-icon-size;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      background-color: $js-menu-buttons-wrapper-background-hover-color;
    }
  }

  .icon-button.menu-icon {
    padding-left: 17px;
  }

  #menu-activator.icon-button.menu-icon {
    padding-left: ($js-page-padding-left - 4px);
  }

  #menu-activator {
    border-right: 1px solid darken($js-menu-buttons-wrapper-background-color, 10%);
  }

  #search-activator {
    //border-right: 1px solid darken($js-menu-buttons-wrapper-background-color,10%);
  }

  #cart-activator {
    float: right;
    padding-right: $js-page-padding-right;
    //border-left: 1px solid darken($js-menu-buttons-wrapper-background-color,10%);
    b {
      padding: 0 5px;
      background-color: white;
      color: $primary-color;
      border-radius: 10px;
      min-width: 16px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      // Do not show this badge if totalItems == 0
      &.digits-0 {
        background-color: transparent;
        color: transparent;
      }
    }
  }

  @media #{$small-only} {
    #menu-buttons-wrapper {
    }

    .icon-button {
      i {
        font-size: $js-menu-buttons-small-icon-size;
      }

      span {
        display: none;
      }
    }
  }

  @media #{$medium-down} {
    #menu-content {
      display: block;
      background-color: $js-menu-buttons-wrapper-background-color;
      clear: both;
      float: none;
      width: 100%;
      @include clearfix();



      .icon-button {
        display: inline-block;

        i {
          font-size: $js-menu-buttons-small-icon-size;
        }

        span {
          display: none;
        }
      }
    }
  }


  @media #{$large-up} {
    #menu-buttons-wrapper {
      display: none;
    }

    #{$menu-buttons-wrapper-content} {
      display: block !important;
    }
  }

  @media #{$xlarge-up} {
    #cat-nav #category-navigation {
      padding-left: $js-page-padding-left;
    }
  }

  @media #{$xxlarge-up} {
    #cat-nav #category-navigation {
      padding-left: $js-page-padding-left;
    }
  }
}