@media print {
  * {
    font-family: $body-font-family;
  }
  .logo-wrapper {
    display: block;
  }
  #header,
  #left-area,
  #right-area,
  #footer,
  #cat-nav,
  .nav-bar,
  .filter-control-body,
  .sort-wrapping,
  .footer2-header,
  .footer2-body,
  .paging-control,
  .paging-control-box ,
  .releware-recommendation-wrapper,
  .product-page-back-link,
  *:after
  {
    display: none;
  }
}

