//
//  SETTINGS
//
$js-cat-nav-background-color: $black;
$js-cat-nav-padding: 0;
$js-cat-nav-border-color: #cdcdcd;

// Common style for level 2 and down
$js-cat-nav-link-color: $js-body-font-color;
$js-cat-nav-item-background: white;
$js-cat-nav-item-background-hover: lighten($js-cat-nav-background-color,90%);
$js-cat-nav-item-active-background: lighten($js-cat-nav-background-color,80%);
$js-cat-nav-item-active-background-hover: lighten($js-cat-nav-background-color,70%);
$js-cat-nav-item-selected-background: lighten($js-cat-nav-background-color,80%);
$js-cat-nav-item-selected-background-hover: lighten($js-cat-nav-background-color,70%);
$js-cat-nav-item-font-size: 13px; // 14px;

// SPACING BETWEEN LINKS
$js-cat-nav-item-spacing: 0;

// INDICATOR FOR VERTICAL-RIGHT SUBCAT-INDICATOR
$js-cat-nav-subcat-indicator-font-size: 16px;
$js-cat-nav-subcat-indicator-content: "\f054";




//
// LV1
//
$js-cat-nav-lv1-item-margin: 0;
$js-cat-nav-lv1-item-padding: 0;
$js-cat-nav-lv1-item-width: auto;
$js-cat-nav-lv1-item-height: 30px;
$js-cat-nav-lv1-link-margin: 5px 0;
$js-cat-nav-lv1-link-height: 20px;
$js-cat-nav-lv1-link-padding: 0 30px 0;
$js-cat-nav-lv1-link-background-color: $js-cat-nav-background-color;
$js-cat-nav-lv1-link-hover-background-color: darken($js-cat-nav-lv1-link-background-color,15%);
$js-cat-nav-lv1-link-active-background-color: darken($js-cat-nav-lv1-link-background-color,20%);
$js-cat-nav-lv1-link-active-hover-background-color: darken($js-cat-nav-lv1-link-background-color,30%);
$js-cat-nav-lv1-link-font-weight: bold;
$js-cat-nav-lv1-link-font-family: $font-family-header;
$js-cat-nav-lv1-link-font-size: $js-cat-nav-item-font-size;
$js-cat-nav-lv1-link-text-decoration: none;
$js-cat-nav-lv1-link-hover-text-decoration: underline;
$js-cat-nav-lv1-link-color: #fff;
$js-cat-nav-lv1-link-hover-color: #fff;
$js-cat-nav-lv1-link-active-color: #fff;
$js-cat-nav-lv1-link-active-hover-color: #fff;
$js-cat-nav-lv1-link-side-border: 1px solid #808080;

//
// LV2
//
$js-cat-nav-lv2-container-border: 0;
$js-cat-nav-lv2-container-padding: 0;
$js-cat-nav-lv2-container-background-color: rgba(255,255,255,0.6);
$js-cat-nav-lv2-container-shadow: 0 2px 3px rgba(0,0,0,0.5);
$js-cat-nav-lv2-item-border-bottom: 1px solid $js-cat-nav-border-color;
$js-cat-nav-lv2-item-margin: 0;
$js-cat-nav-lv2-item-padding: 0;
$js-cat-nav-lv2-item-width: 19%;
$js-cat-nav-lv2-link-padding: 10px 15px 10px 12px;
$js-cat-nav-lv2-link-margin: 1px 0 0;

$js-cat-nav-lv2-link-background-color: $js-cat-nav-item-background;
$js-cat-nav-lv2-link-hover-background-color: $js-cat-nav-item-background-hover;

$js-cat-nav-lv2-link-active-background-color: $js-cat-nav-item-active-background;
$js-cat-nav-lv2-link-active-hover-background-color: $js-cat-nav-item-active-background-hover;

$js-cat-nav-lv2-link-selected-background-color: $js-cat-nav-item-selected-background;
$js-cat-nav-lv2-link-selected-hover-background-color: $js-cat-nav-item-selected-background-hover;

$js-cat-nav-lv2-link-font-weight: bold;
$js-cat-nav-lv2-link-active-font-weight: $js-cat-nav-lv2-link-font-weight;
$js-cat-nav-lv2-link-selected-font-weight: $js-cat-nav-lv2-link-font-weight;
$js-cat-nav-lv2-link-font-family: $js-cat-nav-lv1-link-font-family;
$js-cat-nav-lv2-link-font-size: $js-cat-nav-item-font-size;
$js-cat-nav-lv2-link-line-height: 1;
$js-cat-nav-lv2-link-text-decoration: none;
$js-cat-nav-lv2-link-hover-text-decoration: none;
$js-cat-nav-lv2-link-color: $js-cat-nav-link-color;
$js-cat-nav-lv2-link-hover-color: $js-cat-nav-lv2-link-color;
$js-cat-nav-lv2-link-active-color: $js-body-font-color;
$js-cat-nav-lv2-link-active-hover-color: $js-body-font-color;
$js-cat-nav-lv2-link-selected-color: $js-cat-nav-link-color;
$js-cat-nav-lv2-link-selected-hover-color: $js-cat-nav-link-color;

//
// LV3
//
$js-cat-nav-lv3-container-border: none;
$js-cat-nav-lv3-container-padding: none;
$js-cat-nav-lv3-container-background-color: $js-cat-nav-lv2-container-background-color;
$js-cat-nav-lv3-container-shadow: $js-cat-nav-lv2-container-shadow;
$js-cat-nav-lv3-item-border-bottom: 1px solid $js-cat-nav-border-color;
$js-cat-nav-lv3-item-margin: 0;
$js-cat-nav-lv3-item-padding: 0;
$js-cat-nav-lv3-item-width: auto;
$js-cat-nav-lv3-link-padding:  $js-cat-nav-lv2-link-padding;
$js-cat-nav-lv3-link-margin: 0 0 0px;
$js-cat-nav-lv3-link-background-color: $js-cat-nav-item-background;
$js-cat-nav-lv3-link-hover-background-color: $js-cat-nav-item-background-hover;
$js-cat-nav-lv3-link-active-background-color: $js-cat-nav-item-active-background;
$js-cat-nav-lv3-link-active-hover-background-color: $js-cat-nav-item-active-background-hover;
$js-cat-nav-lv3-link-selected-background-color: $js-cat-nav-item-selected-background;
$js-cat-nav-lv3-link-selected-hover-background-color: $js-cat-nav-item-selected-background-hover;
$js-cat-nav-lv3-link-font-weight:   $js-cat-nav-lv2-link-font-weight;
$js-cat-nav-lv3-link-active-font-weight: $js-cat-nav-lv3-link-font-weight;
$js-cat-nav-lv3-link-selected-font-weight: bold;
$js-cat-nav-lv3-link-font-family: $js-cat-nav-lv2-link-font-family;
$js-cat-nav-lv3-link-font-size: ($js-cat-nav-item-font-size - 1);
$js-cat-nav-lv3-link-line-height: 18px;
$js-cat-nav-lv3-link-text-decoration: none;
$js-cat-nav-lv3-link-hover-text-decoration: none;
$js-cat-nav-lv3-link-color: $js-cat-nav-link-color;
$js-cat-nav-lv3-link-hover-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv3-link-active-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv3-link-active-hover-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv3-link-selected-color: $js-cat-nav-link-color;
$js-cat-nav-lv3-link-selected-hover-color: $js-cat-nav-link-color;

//
// LV4
//
$js-cat-nav-lv4-container-border: none;
$js-cat-nav-lv4-container-padding: none;
$js-cat-nav-lv4-container-background-color: transparent;
$js-cat-nav-lv4-item-margin: 0;
$js-cat-nav-lv4-item-padding: 0;
$js-cat-nav-lv4-item-width: auto;
$js-cat-nav-lv4-link-padding:  $js-cat-nav-lv3-link-padding;
$js-cat-nav-lv4-link-padding:  10px 15px 10px 18px;
$js-cat-nav-lv4-link-margin: 1px 0 0;
$js-cat-nav-lv4-link-background-color: $js-cat-nav-item-background;
$js-cat-nav-lv4-link-hover-background-color: $js-cat-nav-item-background-hover;
$js-cat-nav-lv4-link-active-background-color: $js-cat-nav-item-active-background;
$js-cat-nav-lv4-link-active-hover-background-color: $js-cat-nav-item-active-background-hover;
$js-cat-nav-lv4-link-selected-background-color: $js-cat-nav-item-selected-background;
$js-cat-nav-lv4-link-selected-hover-background-color: $js-cat-nav-item-selected-background-hover;
$js-cat-nav-lv4-link-font-weight: normal;
$js-cat-nav-lv4-link-active-font-weight: $js-cat-nav-lv3-link-font-weight;
$js-cat-nav-lv4-link-selected-font-weight: bold;
$js-cat-nav-lv4-link-font-family: $js-cat-nav-lv3-link-font-family;
$js-cat-nav-lv4-link-font-size: ($js-cat-nav-item-font-size - 2);
$js-cat-nav-lv4-link-line-height: 18px;
$js-cat-nav-lv4-link-text-decoration: none;
$js-cat-nav-lv4-link-hover-text-decoration: none;
$js-cat-nav-lv4-link-color: $js-cat-nav-link-color;
$js-cat-nav-lv4-link-hover-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv4-link-active-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv4-link-active-hover-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv4-link-selected-color: $js-cat-nav-lv3-link-color;
$js-cat-nav-lv4-link-selected-hover-color: $js-cat-nav-lv3-link-color;