
#checkout-upsell-wrapper {
    h2 { 
        font-size: .9rem;
        text-transform: initial;
        max-width: 685px;
        text-align: center;
        margin: .5rem auto;
    }
}

#checkout-upsell {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .upsell-item {
        margin: 0 1rem;
        width: 15%;

        img {
            width: 100%;
        }

        .upsell-item-button {
            width: 100%;
            button {
                width: 100%;
            }
        }
    }

}

@media #{$medium-down} {
    #content-wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }
    #checkout-upsell {
        .upsell-item {
            width: 19%;
        }
    }
}

@media #{$small-only} {
    #checkout-upsell {
    flex-wrap: nowrap;
    .upsell-item {
        width: 40%;
        margin: 0 0.3rem;
        .upsell-item-button {
            width: 100%;
            button {
                span { 
                    display: none;
                }
            }
        }
    }
}
}